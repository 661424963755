<template>
  <div
    :class="`mobile-sidebar bg-white dark:bg-slate-800  ${
      this.$store.state.theme === 'bordered'
        ? 'border border-gray-5002'
        : 'shadow-base'
    }`"
  >
    <div class="logo-segment flex justify-between items-center px-4 py-6">
      <router-link :to="homeRoute">
        <img
          src="@/assets/images/logo/logo.svg"
          alt=""
          v-if="!this.$store.state.isDark"
        />
        <img
          src="@/assets/images/logo/logo-white.svg"
          alt=""
          v-if="this.$store.state.isDark"
        />
      </router-link>
      <span
        class="cursor-pointer text-slate-900 dark:text-white text-2xl"
        @click="toggleMsidebar"
        ><Icon icon="heroicons:x-mark"
      /></span>
    </div>

    <div class="sidebar-menu px-4 h-[calc(100%-100px)]" data-simplebar>
      <Navmenu :items="menuItems" />
      <!-- <div
        class="bg-slate-900 mb-[100px] mt-14 p-4 relative text-center rounded-2xl text-white"
        v-if="!this.$store.state.sidebarCollasp"
      >

      </div> -->
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Navmenu from "./Navmenu";

export default defineComponent({
  components: {
    Icon,
    Navmenu,
  },
  setup() {
    const store = useStore();

    // Fetch user information
    const activeUser = JSON.parse(localStorage.getItem("activeUser")) || {};
    const userRole = activeUser.role_id || null;

    // Computed property for menu items
    const menuItems = computed(() => store.getters.menuItems);

    // Fetch menu items if not already loaded
    const fetchMenuItems = async () => {
      if (!menuItems.value.length) {
        await store.dispatch("fetchMenuItems");
      }
    };

    // Computed property for dynamic home route
    const homeRoute = computed(() => {
      switch (userRole) {
        case 9:
          return { name: "authorhome" };
        case 3:
          return { name: "inithome" };
        case 4:
          return { name: "project-managerhome" };
        case 8:
          return { name: "suhome" };
        default:
          return { name: "project-managerhome" };
      }
    });

    // Toggle mobile sidebar
    const toggleMsidebar = () => {
      store.dispatch("toggleMsidebar");
    };

    // Lifecycle hook to fetch menu items
    onMounted(fetchMenuItems);

    return {
      homeRoute,
      menuItems,
      toggleMsidebar,
    };
  },
});
</script>

<style lang="scss" scoped>
.mobile-sidebar {
  @apply fixed left-0 top-0 h-full z-[9999] w-[280px];
}
</style>
