<template>
  <router-link :to="userRole === 9 ? { name: 'authorhome' } : userRole === 3 ? { name: 'inithome' } : userRole === 8 ? { name: 'suhome' } : userRole === 7 ? { name: 'partnerhome' } : userRole === 4 ? { name: 'project-managerhome' } : { name: 'project-managerhome' }">
    <img
      src="@/assets/images/logo/logo-c.svg"
      alt=""
      v-if="!this.$store.state.isDark"
    />

    <img
      src="@/assets/images/logo/logo-c-white.svg"
      alt=""
      v-if="this.$store.state.isDark"
    />
  </router-link>
</template>
<script>
import { defineComponent, ref} from "vue";

export default defineComponent({
  setup() {
    const activeUser = JSON.parse(localStorage.getItem('activeUser')) || {};
    const userRole = ref(activeUser.role_id || null);



    return {
      userRole,
    };
  }
});
</script>