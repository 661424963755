import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    addModal: false,
    editModal: false,
    todoSearch: "",
    editModalId: null,
    mobileTodoSidebar: false,
    isLoading: false,
    todos: [],
    trashTodo: [],
    editItem: {},
  },
  getters: {
    isLoading: (state) => state.isLoading,
    todoSearch: (state) => state.todoSearch,
    todos: (state) =>
      state.todoSearch === ""
        ? state.todos
        : state.todos.filter((item) =>
            item.title.toLowerCase().includes(state.todoSearch.toLowerCase())
          ),
    isDone: (state) => state.todos.filter((item) => item.is_done),
    isFav: (state) => state.todos.filter((item) => item.is_fav),
    high: (state) => state.todos.filter((item) => item.priority === "high"),
    medium: (state) => state.todos.filter((item) => item.priority === "medium"),
    low: (state) => state.todos.filter((item) => item.priority === "low"),
    team: (state) => state.todos.filter((item) => item.priority === "team"),
    update: (state) => state.todos.filter((item) => item.priority === "update"),
  },
  mutations: {
    SET_TODO_SEARCH(state, query) {
      state.todoSearch = query;
    },
    SET_TODOS(state, todos) {
      state.todos = todos;
    },
    ADD_TODO(state, todo) {
      state.todos.unshift(todo);
    },
    UPDATE_TODO(state, updatedTodo) {
      const index = state.todos.findIndex((todo) => todo.id === updatedTodo.id);
      if (index !== -1) {
        state.todos.splice(index, 1, updatedTodo);
      }
    },
    REMOVE_TODO(state, todoId) {
      state.todos = state.todos.filter((todo) => todo.id !== todoId);
    },
    SET_LOADING(state, status) {
      state.isLoading = status;
    },
    OPEN_TODO(state) {
      state.addModal = true;
    },
    CLOSE_TODO(state) {
      state.addModal = false;
    },
    OPEN_EDIT_MODAL(state, todo) {
      state.editModal = true;
      state.editItem = todo;
    },
    CLOSE_EDIT_MODAL(state) {
      state.editModal = false;
      state.editItem = {};
    },
    SORT_AZ(state) {
      state.todos.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    },
    SORT_ZA(state) {
      state.todos.sort((a, b) => {
        return b.title.localeCompare(a.title);
      });
    },
    // RESET_SORT
    RESET_SORT(state) {
      state.todos.sort((a, b) => {
        return a.id.localeCompare(b.id);
      });
    },
  },
  actions: {
    async fetchTodos({ commit }) {
      commit("SET_LOADING", true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get("https://apidash.smartpress.kz/api/todos", {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        commit("SET_TODOS", response.data);
      } catch (error) {
        toast.error("Failed to fetch todos");
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async addTodo({ commit }, newTodo) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post("https://apidash.smartpress.kz/api/todos", newTodo, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        commit("ADD_TODO", response.data);
        toast.success("Task added");
      } catch (error) {
        toast.error("Failed to add task");
      }
    },
    async updateTodo({ commit }, todo) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.put(`https://apidash.smartpress.kz/api/todos/${todo.id}`, todo, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        commit("UPDATE_TODO", response.data);
        toast.success("Task updated");
      } catch (error) {
        toast.error("Failed to update task");
      }
    },
    async removeTodo({ commit }, todoId) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`https://apidash.smartpress.kz/api/todos/${todoId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        commit("REMOVE_TODO", todoId);
        toast.success("Task deleted");
      } catch (error) {
        toast.error("Failed to delete task");
      }
    },
    async toggleDone({ commit }, todoId) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.patch(
          `https://apidash.smartpress.kz/api/todos/${todoId}/toggle-done`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        commit("UPDATE_TODO", response.data.todo);
      } catch (error) {
        console.error("Failed to toggle done:", error);
      }
    },
    
    async toggleFav({ commit }, todoId) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.patch(
          `https://apidash.smartpress.kz/api/todos/${todoId}/toggle-fav`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        commit("UPDATE_TODO", response.data.todo);
      } catch (error) {
        console.error("Failed to toggle favorite:", error);
      }
    },
    
    openTodo({ commit }) {
      commit("OPEN_TODO");
    },
    closeTodo({ commit }) {
      commit("CLOSE_TODO");
    },
    openEditModal({ commit }, todo) {
      commit("OPEN_EDIT_MODAL", todo);
    },
    closeEditModal({ commit }) {
      commit("CLOSE_EDIT_MODAL");
    },
    setTodoSearch({ commit }, query) {
      commit("SET_TODO_SEARCH", query);
    },
    sortAZ({ commit }) {
      commit("SORT_AZ");
    },

    sortZA({ commit }) {
      commit("SORT_ZA");
    },

    resetSort({ commit }) {
      commit("RESET_SORT");
    },
  },
};
