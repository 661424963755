import axios from 'axios';

const state = {
  offers: [],
  addKpModal: false, // Modal visibility state for Add Invoice
  addInvoiceModal: false, // Modal visibility state for Add Invoice
  invoiceData: null, // Placeholder for any initial data
  offerDetails: null, // Store the fetched offer details
  currentOffer: null, // Store the current clicked tirage
  totalOffers: 0,
  selectedOffer: null, // Store the selected offer for the modal
  showPaymentModal: false, // Control the payment modal visibility
};

const getters = {
  offers: (state) => state.offers,
  isLoading: (state) => state.isLoading,
  addKpModal: (state) => state.addKpModal,
  addInvoiceModal: (state) => state.addInvoiceModal,
  invoiceData: (state) => state.invoiceData, // Getter for invoice data if needed
  offerDetails: (state) => state.offerDetails || {},
  currentOffer: (state) => state.currentOffer,
  selectedOffer: (state) => state.selectedOffer,
  showPaymentModal: (state) => state.showPaymentModal,
};

const mutations = {
  SET_OFFERS(state, offers) {
    state.offers = offers;
  },
  SET_TOTAL_OFFERS(state, total) {
    state.totalOffers = total;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ADD_KP_MODAL(state, status) {
    state.addKpModal = status; // Toggle modal visibility
  },
  SET_ADD_INVOICE_MODAL(state, status) {
    state.addInvoiceModal = status; // Toggle Invoice modal visibility
  },
  SET_INVOICE_DATA(state, data) {
    state.invoiceData = data; // Set invoice data if needed
  },
  CLOSE_ADD_KP_MODAL(state) {
    state.addKpModal = false;
    state.invoiceData = null; // Clear invoice data when closing
  },
  CLOSE_ADD_INVOICE_MODAL(state) {
    state.addInvoiceModal = false;
    state.invoiceData = null; // Clear invoice data when closing Invoice modal
  },
  SET_OFFER_DETAILS(state, offer) {
    state.offerDetails = offer; // Set the offer details
  },
  SET_CURRENT_OFFER(state, offer) {
    state.currentOffer = offer; // Set the clicked tirage
  },
  SET_SELECTED_OFFER(state, offer) {
    state.selectedOffer = offer;
  },
  SET_SHOW_PAYMENT_MODAL(state, status) {
    state.showPaymentModal = status;
  },
};

const actions = {
  async fetchOffers({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/offers', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      commit('SET_OFFERS', response.data.offers);
      commit('SET_TOTAL_OFFERS', response.data.offers.length); // Set total offers
    } catch (error) {
      console.error('Error fetching tirages:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  openPaymentModal({ commit }, offer) {
    if (offer && offer.id) {
      console.log("Opening modal with offer ID:", offer.id); // Debug log to ensure offer is passed correctly
      commit("SET_SELECTED_OFFER", offer);
      commit("SET_SHOW_PAYMENT_MODAL", true);
    } else {
      console.error("Offer is undefined or missing ID:", offer); // Debug error if no offer or ID
    }
  },
  closePaymentModal({ commit }) {
    commit("SET_SHOW_PAYMENT_MODAL", false); // Закрыть модальное окно
    commit("SET_SELECTED_OFFER", null); // Очистить выбранное предложение
  },
  openKPModal({ commit }, invoiceData) {
    commit('SET_CURRENT_OFFER', invoiceData); // Optionally pass some data to the modal
    commit('SET_ADD_KP_MODAL', true); // Show modal
  },
  openInvoiceModal({ commit }, offerData) {
    commit('SET_CURRENT_OFFER', offerData); // Pass the offer data to the modal
    commit('SET_ADD_INVOICE_MODAL', true); // Show Invoice modal
  },
  closeKpModal({ commit }) {
    commit('CLOSE_ADD_KP_MODAL'); // Close modal
  },
  closeInvoiceModal({ commit }) {
    commit('CLOSE_ADD_INVOICE_MODAL'); // Close Invoice modal
  },
  async createOffer({ commit }, invoiceData) {
    try {
      const token = localStorage.getItem('token'); // Use token for authentication
      const response = await axios.post(
        'https://apidash.smartpress.kz/api/offers', // Replace with your API endpoint
        invoiceData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure correct content type
          },
        }
      );
      console.log('Offer created:', response.data);

      // Optionally, you can fetch updated data or show a success notification here
    } catch (error) {
      console.error('Error creating offer:', error);
      throw error; // Re-throw the error to handle in the component if needed
    }
  },

  async fetchOfferDetails({ commit }, offerId) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/offers/${offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_OFFER_DETAILS', response.data);
    } catch (error) {
      console.error('Error fetching offer details:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateOffer({ dispatch }, offerData) {
    try {
      const token = localStorage.getItem('token');
      const offerId = offerData.get('offer_id'); // Access the offer ID from FormData
  
      // Send POST request to update the offer
      const response = await axios.post(
        `https://apidash.smartpress.kz/api/offers/${offerId}`,
        offerData, 
        {
          headers: {
            'Authorization': `Bearer ${token}`, // No need to manually set 'Content-Type'
          },
        }
      );
      
      console.log('Offer updated:', response.data);
      // commit("SET_SELECTED_OFFER", null); // Clear selected offer after update
      await dispatch('fetchOffers'); // This will update the offers list in the state
    } catch (error) {
      console.error('Error updating offer:', error);
      throw error;
    }
  },
  async updateOfferInvoice({ dispatch }, invoiceData) {
    try {
      const token = localStorage.getItem('token');
  
      // Check if token is not null or empty
      if (!token) {
        throw new Error('Token is missing. Please login again.');
      }
  
      // Make the API call to update the invoice
      const response = await axios.post(
        `https://apidash.smartpress.kz/api/offers/${invoiceData.offerId}/update-invoice`, // Adjust the API endpoint accordingly
        invoiceData,  // Payload can be empty if you're just updating the status
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('Invoice updated:', response.data);
  
      // Optionally, dispatch another action to refresh the offers list
      await dispatch('fetchOffers'); 
  
    } catch (error) {
      console.error('Error updating invoice:', error);
      throw error; // Re-throw the error to handle in the component if needed
    }
  },
  async saveInvoice({ commit, state }, { offer_id, progress }) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://apidash.smartpress.kz/api/invoices/${offer_id}/update-progress`, {
        progress,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Find the offer in the state and update it with the new progress
      const updatedOffers = state.offers.map(offer => 
        offer.id === offer_id ? { ...offer, progress } : offer
      );
      
      // Commit the updated offers list to the state
      commit('SET_OFFERS', updatedOffers);
      
    } catch (error) {
      console.error('Error updating progress:', error);
    }
  },
  
 
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
