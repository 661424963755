// store/index.js
import { createStore } from "vuex";
import chat from "./app/chat";
import email from "./app/email";
import kanban from "./kanban";
import project from "./app/project";
import apptodo from "./tasks";
import applications from "./author/applications";
import app from "./initiator/applications";
import support from "./support";
import offers from "./accounting/offers";
import techcards from "./techcards";
import profile from "./author/profile";
import userprofile from "./profile";
import projects from "./projects";
import todos from "./todos";
import axios from 'axios';

export default createStore({
  state: {
    sidebarCollasp: false,
    sidebarHidden: false,
    mobielSidebar: false,
    semidark: false,
    semiDarkTheme: "semi-light",
    isDark: false,
    skin: "default",
    theme: "light",
    isOpenSettings: false,
    cWidth: "full",
    menuLayout: "vertical",
    navbarType: "sticky",
    footerType: "static",
    chartColors: {
      title: "red",
    },
    menuItems: [],
  },
  getters: {
    theme: (state) => state.theme,
    skin: (state) => state.skin,
    menuItems: state => state.menuItems,
  },
  mutations: {
    setSidebarCollasp(state) {
      state.sidebarCollasp = !state.sidebarCollasp;
    },
    
    SET_MENU_ITEMS(state, menuItems) {
      state.menuItems = menuItems;
    },

    toogleDark(state) {
      state.isDark = !state.isDark;
      state.theme = state.theme === "dark" ? "light" : "dark";
      document.body.classList.toggle(state.theme);
      localStorage.setItem("theme", state.theme);
    },

    toggleSettings(state) {
      state.isOpenSettings = !state.isOpenSettings;
    },
    toggleMsidebar(state) {
      state.mobielSidebar = !state.mobielSidebar;
    },
    toggleSemiDark(state) {
      state.semidark = !state.semidark;
      state.semiDarkTheme = state.semidark ? "semi-dark" : "semi-light";
      document.body.classList.toggle(state.semiDarkTheme);
      localStorage.setItem("semiDark", state.semidark);
    },
  },
  actions: {
    // toogleDark
    toogleDark({ commit }) {
      commit("toogleDark");
    },
    // toggleSettings
    toggleSettings({ commit }) {
      commit("toggleSettings");
    },
    // setSidebarCollasp
    setSidebarCollasp({ commit }) {
      commit("setSidebarCollasp");
    },
    // toggleMsidebar
    toggleMsidebar({ commit }) {
      commit("toggleMsidebar");
    },
    toggleSemiDark({ commit }) {
      commit("toggleSemiDark");
    },

    async fetchMenuItems({ commit }) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://apidash.smartpress.kz/api/menu-items', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        commit('SET_MENU_ITEMS', response.data);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    },
  },
  modules: {
    apptodo,
    project,
    kanban,
    email,
    chat,
    applications,
    profile,
    app,
    techcards,
    projects,
    todos,
    userprofile,
    offers,
    support
  },
});
