import { createRouter, createWebHistory } from "vue-router";
import store from '@/store';
import Layout from '@/Layout/index.vue';

function guard(to, from, next) {
  if (localStorage.activeUser) {
    next();
  } else next({ name: "Login" });
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/auth/login/index.vue"),
    meta: {
      titles: {
        en: "Login",
        ru: "Вход",
        kz: "Кіру"
      }
    }
  },
  {
    path: "/register",
    name: "reg",
    component: () => import("@/views/auth/register"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/forgot-password.vue"),
  },
  {
    path: "/forgot-password2",
    name: "forgot-password2",
    component: () => import("@/views/auth/forgot-password2.vue"),
  },
  {
    path: "/forgot-password3",
    name: "forgot-password3",
    component: () => import("@/views/auth/forgot-password3.vue"),
  },
  {
    path: "/lock-screen",
    name: "lock-screen",
    component: () => import("@/views/auth/lock-screen.vue"),
  },
  {
    path: "/lock-screen2",
    name: "lock-screen2",
    component: () => import("@/views/auth/lock-screen2.vue"),
  },
  {
    path: "/lock-screen3",
    name: "lock-screen3",
    component: () => import("@/views/auth/lock-screen3.vue"),
  },
  {
    path: "/success-500",
    name: "success-500",
    component: () => import("@/views/auth/success.vue"),
  },
  {
    path: "/app",
    name: "Layout",
    redirect: "/app/home",
    component: () => import("@/Layout/index.vue"),
    beforeEnter: guard,
    children: [      
      {
        path: "blank-page",
        name: "blank-page",
        component: () => import("@/views/blank-page.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        component: () => import("@/views/notifications.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "ecommerce",
        name: "ecommerce",
        component: () => import("@/views/home/ecommerce.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "banking",
        name: "banking",
        component: () => import("@/views/home/banking.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "crm",
        name: "crm",
        component: () => import("@/views/home/crm.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "project",
        name: "project",
        component: () => import("@/views/home/project.vue"),
        meta: {
          hide: true,
        },
      },

      // components
      {
        path: "button",
        name: "button",
        component: () => import("@/views/components/button/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "alert",
        name: "alert",
        component: () => import("@/views/components/alert/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "card",
        name: "card",
        component: () => import("@/views/components/card/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "carousel",
        name: "carousel",
        component: () => import("@/views/components/carousel.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "dropdown",
        name: "dropdown",
        component: () => import("@/views/components/dropdown/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "modal",
        name: "dodal",
        component: () => import("@/views/components/modal/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "tab-accordion",
        name: "tab-accordion",
        component: () => import("@/views/components/tab-accordion/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "badges",
        name: "badges",
        component: () => import("@/views/components/badges.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "tooltip-popover",
        name: "tooltip-popover",
        component: () => import("@/views/components/tooltip-popover.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "typography",
        name: "typography",
        component: () => import("@/views/components/typography.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "colors",
        name: "colors",
        component: () => import("@/views/components/colors.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "image",
        name: "image",
        component: () => import("@/views/components/image/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "video",
        name: "video",
        component: () => import("@/views/components/video.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "pagination",
        name: "pagination",
        component: () => import("@/views/components/pagination"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "progress-bar",
        name: "progress-bar",
        component: () => import("@/views/components/progress-bar/index.vue"),
        meta: {
          groupParent: "components",
        },
      },
      {
        path: "placeholder",
        name: "placeholder",
        component: () => import("@/views/components/placeholder.vue"),
        meta: {
          groupParent: "placeholder",
        },
      },
      // widgets
      {
        path: "basic",
        name: "basic",
        component: () => import("@/views/widgets/basic.vue"),
        meta: {
          groupParent: "widgets",
        },
      },
      {
        path: "statistic",
        name: "statistic",
        component: () => import("@/views/widgets/statistic.vue"),
        meta: {
          groupParent: "widgets",
        },
      },

      // forms
      {
        path: "input",
        name: "input",
        component: () => import("@/views/forms/input"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "input-group",
        name: "input-group",
        component: () => import("@/views/forms/input-group"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "input-layout",
        name: "input-layout",
        component: () => import("@/views/forms/input-layout"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "form-validation",
        name: "form-validation",
        component: () => import("@/views/forms/form-validation"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "form-wizard",
        name: "form-wizard",
        component: () => import("@/views/forms/form-wizard"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "form-repeater",
        name: "form-repeater",
        component: () => import("@/views/forms/form-repeater"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "input-mask",
        name: "input-mask",
        component: () => import("@/views/forms/input-mask"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "file-input",
        name: "file-input",
        component: () => import("@/views/forms/file-input"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "checkbox",
        name: "checkbox",
        component: () => import("@/views/forms/checkbox.vue"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "radio-button",
        name: "radio-button",
        component: () => import("@/views/forms/radio-button.vue"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "textarea",
        name: "textarea",
        component: () => import("@/views/forms/textarea"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "switch",
        name: "switch",
        component: () => import("@/views/forms/switch"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "select",
        name: "select",
        component: () => import("@/views/forms/select"),
        meta: {
          groupParent: "forms",
        },
      },
      {
        path: "date-time-picker",
        name: "date-time-picker",
        component: () => import("@/views/forms/date-time-picker"),
        meta: {
          groupParent: "forms",
        },
      },
      // table view
      {
        path: "table-basic",
        name: "table-basic",
        component: () => import("@/views/table/basic"),
        meta: {
          groupParent: "Table",
        },
      },
      {
        path: "table-advanced",
        name: "table-advanced",
        component: () => import("@/views/table/advanced"),
        meta: {
          groupParent: "Table",
        },
      },
      // chart
      {
        path: "appex-chart",
        name: "appex-chart",
        component: () => import("@/views/chart/appex-chart"),
        meta: {
          groupParent: "charts",
        },
      },
      {
        path: "chartjs",
        name: "chartjs",
        component: () => import("@/views/chart/chartjs"),
        meta: {
          groupParent: "charts",
        },
      },
      // app
      {
        path: "calender",
        name: "calender",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "todo",
        name: "todo",
        component: () => import("@/views/app/todo"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "kanban",
        name: "kanban",
        component: () => import("@/views/app/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "email",
        name: "email",
        component: () => import("@/views/app/email"),
        meta: {
          groupParent: "Project",
          hide: true,
          appheight: true,
        },
      },
      {
        path: "initiator/home",
        name: "inithome",
        component: () => import("@/views/initiator/home"),
      },
      {
        path: "initiator/support",
        name: "initsupport",
        component: () => import("@/views/support/Support.vue"),
      },
      {
        path: "deputy/support",
        name: "deputysupport",
        component: () => import("@/views/support/SupportDashboard.vue"),
      },
      {
        path: "initiator/techcards",
        name: "Техкарты Инициатор",
        component: () => import("@/views/initiator/techcards"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.techcards",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "initiator/offers",
        name: "initiatorOffers",
        component: () => import("@/views/initiator/offers"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.initiatorOffers",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "initiator/chat",
        name: "initiatorChat",
        component: () => import("@/views/initiator/chat"),
        meta: {
          hide: true,
          appheight: true,
          titleKey: "breadcrumb.initiatorChat",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "stock/chat",
        name: "stockChat",
        component: () => import("@/views/stock/chat"),
        meta: {
          hide: true,
          appheight: true,
          titleKey: "breadcrumb.initiatorChat",
          groupParent: "breadcrumb.stock",
        },
      },
      {
        path: "accounting/chat",
        name: "accountingChat",
        component: () => import("@/views/accounting/chat"),
        meta: {
          hide: true,
          appheight: true,
          titleKey: "breadcrumb.initiatorChat",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "partner/chat",
        name: "partnerChat",
        component: () => import("@/views/partner/chat"),
        meta: {
          hide: true,
          appheight: true,
          titleKey: "breadcrumb.initiatorChat",
          groupParent: "breadcrumb.partner",
        },
      },
      {
        path: "typography/chat",
        name: "typographyChat",
        component: () => import("@/views/typography/chat"),
        meta: {
          hide: true,
          appheight: true,
          titleKey: "breadcrumb.initiatorChat",
          groupParent: "breadcrumb.typography",
        },
      },
      {
        path: "initiator/booktracking",
        name: "bookTrackingInitiator",
        component: () => import("@/views/initiator/techcards/BookTracking.vue"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.bookTrackingInitiator",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "initiator/kanban",
        name: "kanbanInitiator",
        component: () => import("@/views/initiator/kanban"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.kanbanInitiator",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "initiator/calendar",
        name: "Calender Initiator",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "initiator/applications",
        name: "Заявки авторов",
        component: () => import("@/views/initiator/applications"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.applications",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "initiator/appstatus",
        name: "Статус заявки",
        component: () => import("@/views/initiator/applications/ChangeStatus.vue"), // Adjusted import path
        meta: {
          hide: true,
        },
      },
      {
        path: "initiator/application-details",
        name: "Страница заявки",
        component: () => import("@/views/initiator/applications/application-details.vue"), // Adjusted import path
        meta: {
          hide: true,
        },
      },
      {
        path: "author/calendar",
        name: "Calender Author",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "author/home",
        name: "authorhome",
        component: () => import("@/views/author/home"),
        meta: {
          titles: {
            en: "Author Home",
            ru: "Главная страница",
            kz: "Басты бет"
          }
        }
      },
      {
        path: "author/chat",
        name: "Author chat",
        component: () => import("@/views/author/chat"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "author/kanban",
        name: "Kanban author",
        component: () => import("@/views/author/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "author/applications",
        name: "applications",
        component: () => import("@/views/author/applications"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.applications",
          groupParent: "breadcrumb.author",
        },
      },
      {
        path: "author/application-details",
        name: "application-details",
        component: () => import("@/views/author/applications/application-details.vue"), // Adjusted import path
        meta: {
          hide: true,
        },
      },
      {
        path: 'author/profile/:id',
        name: 'author-profile',
        component: () => import("@/views/author/profile"),
        meta: {
          hide: true,
        },
      },
      {
        path: 'author/profile-setting',
        name: 'Author Profile Setting',
        component: () => import("@/views/author/profile/profile-setting.vue"),
      },
      {
        path: "author/offers",
        name: "authoroffers",
        component: () => import("@/views/author/offers"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.offers",
          groupParent: "breadcrumb.author",
        },
      },
      {
        path: "author/invoice-preview",
        name: "InvoicePreview Author",
        component: () => import("@/views/author/offers/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.invoicePreviewAuthor",
          groupParent: "breadcrumb.author",
        },
      },
      {
        path: "author/invoice-preview",
        name: "KPPreview Author",
        component: () => import("@/views/author/offers/kp-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.kpPreviewAuthor",
          groupParent: "breadcrumb.author",
        },
      },
      {
        path: "initiator/invoice-preview",
        name: "InvoicePreview Init",
        component: () => import("@/views/initiator/offers/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.invoicePreviewAuthor",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "initiator/invoice-preview",
        name: "KPPreview Init",
        component: () => import("@/views/initiator/offers/kp-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.kpPreviewAuthor",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "project-manager/invoice-preview",
        name: "InvoicePreview PM",
        component: () => import("@/views/project-manager/offers/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.invoicePreviewAuthor",
          groupParent: "breadcrumb.projectManager",
        },
      },
      {
        path: "project-manager/invoice-preview",
        name: "KPPreview PM",
        component: () => import("@/views/project-manager/offers/kp-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.kpPreviewAuthor",
          groupParent: "breadcrumb.projectManager",
        },
      },
      {
        path: "author/booktracking",
        name: "BookTracking author",
        component: () => import("@/views/author/techcards/BookTracking.vue"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.bookTrackingInitiator",
          groupParent: "breadcrumb.author",
        },
      },
      {
        path: "executor/home",
        name: "executorhome",
        component: () => import("@/views/executor/home"),
      },
      {
        path: "executor/kanban",
        name: "Kanban executor",
        component: () => import("@/views/executor/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "executor/todo",
        name: "Задачи Исполнитель",
        component: () => import("@/views/executor/todo"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "executor/chat",
        name: "Executor chat",
        component: () => import("@/views/executor/chat"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "executor/calendar",
        name: "Calender Executor",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "executor/projects",
        name: "Новые проекты",
        component: () => import("@/views/executor/projects"),
        meta: {
          hide: true,
        },
      },
      {
        path: "executor/project-details",
        name: "Страница проекта(Исполнитель)",
        component: () => import("@/views/executor/projects/project-details.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "project-manager/home",
        name: "project-managerhome",
        component: () => import("@/views/project-manager/home"),
      },
      {
        path: "project-manager/offers",
        name: "Мои документы(project-manager)",
        component: () => import("@/views/project-manager/offers"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.offers",
          groupParent: "breadcrumb.projectManager",
        },
      },
      {
        path: "project-manager/chat",
        name: "Project-manager chat",
        component: () => import("@/views/project-manager/chat"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "project-manager/calendar",
        name: "Calender PM",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "project-manager/kanban",
        name: "Kanban PM",
        component: () => import("@/views/project-manager/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "project-manager/todo",
        name: "Задачи",
        component: () => import("@/views/project-manager/todo"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "project-manager/projects",
        name: "projectsPM",
        component: () => import("@/views/project-manager/projects"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.projectsPM",
          groupParent: "breadcrumb.projectManager",
        },
      },
      {
        path: "project-manager/project-details",
        name: "Страница проекта",
        component: () => import("@/views/project-manager/projects/project-details.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "project-manager/techcards",
        name: "pmtechCards",
        component: () => import("@/views/project-manager/techcards"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.techcards",
          groupParent: "breadcrumb.projectManager",
        },
      },
      {
        path: "project-manager/booktracking",
        name: "BookTrackingPM",
        component: () => import("@/views/project-manager/techcards/BookTracking.vue"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.bookTrackingInitiator",
          groupParent: "breadcrumb.projectManager",
        },
      },
      {
        path: "typography/kanban",
        name: "Kanban Typography",
        component: () => import("@/views/typography/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "typography/calendar",
        name: "Calender Typography",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "typography/techcards",
        name: "Техкарты Typography",
        component: () => import("@/views/typography/techcards"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.techcards",
          groupParent: "breadcrumb.typography",
        },
      },
      {
        path: "typography/booktracking",
        name: "BookTrackingTypography",
        component: () => import("@/views/typography/techcards/BookTracking.vue"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.bookTrackingInitiator",
          groupParent: "breadcrumb.typography",
        },
      },
      {
        path: "typography/invoice-preview/:id",
        name: "TechCardPreview Typography",
        component: () => import("@/views/typography/techcards/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.techCardPreviewInit",
          groupParent: "breadcrumb.typography",
        },
      },
      {
        path: "accounting/home",
        name: "accountinghome",
        component: () => import("@/views/accounting/home"),
      },
      {
        path: "partner/home",
        name: "partnerhome",
        component: () => import("@/views/partner/home"),
      },
      {
        path: "accounting/todo",
        name: "Задачи Accounting",
        component: () => import("@/views/accounting/todo"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "partner/todo",
        name: "Задачи Partner",
        component: () => import("@/views/partner/todo"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "accounting/kanban",
        name: "Accounting Kanban",
        component: () => import("@/views/accounting/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "partner/kanban",
        name: "Partner Kanban",
        component: () => import("@/views/partner/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "stock/kanban",
        name: "Stock Kanban",
        component: () => import("@/views/stock/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "stock/booktracking",
        name: "BookTrackingStock",
        component: () => import("@/views/stock/techcards/BookTracking.vue"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.bookTrackingInitiator",
          groupParent: "breadcrumb.stock",
        },
      },
      {
        path: "accounting/calendar",
        name: "Calender accounting",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "partner/calendar",
        name: "Calender Partner",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "stock/calendar",
        name: "Calender stock",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "accounting/techcards",
        name: "Техкарты accounting",
        component: () => import("@/views/accounting/techcards"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.techcards",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "stock/techcards",
        name: "Техкарты stock",
        component: () => import("@/views/stock/techcards"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.techcards",
          groupParent: "breadcrumb.stock",
        },
      },
      {
        path: "accounting/invoice-preview/:id",
        name: "TechCardPreview",
        component: () => import("@/views/accounting/techcards/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.techCardPreviewInit",
          groupParent: "breadcrumb.accounting",
        },
      },

      {
        path: "supervisor/invoice-preview/:id",
        name: "TechCardPreview SU",
        component: () => import("@/views/supervisor/techcards/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.techCardPreviewInit",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/chat",
        name: "Super-user chat",
        component: () => import("@/views/supervisor/chat"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      {
        path: "supervisor/applications",
        name: "Заявки авторов SU",
        component: () => import("@/views/supervisor/applications"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.applications",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/home",
        name: "suhome",
        component: () => import("@/views/supervisor/home"),
      },
      {
        path: "supervisor/application-details",
        name: "Страница заявки SU",
        component: () => import("@/views/supervisor/applications/application-details.vue"), // Adjusted import path
        meta: {
          hide: true,
        },
      },
      {
        path: "supervisor/projects",
        name: "projectsSU",
        component: () => import("@/views/supervisor/projects"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.projectsPM",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/users",
        name: "users",
        component: () => import("@/views/supervisor/users"),
        meta: {
          hide: true,
          titleKey: "Users",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/project-details",
        name: "Страница проекта SU",
        component: () => import("@/views/supervisor/projects/project-details.vue"),
        meta: {
          hide: true,
        },
      },
      {
        path: "project-manager/invoice-preview/:id",
        name: "TechCardPreview PM",
        component: () => import("@/views/project-manager/techcards/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.techCardPreviewInit",
          groupParent: "breadcrumb.projectManager",
        },
      },
      {
        path: "initiator/invoice-preview/:id",
        name: "TechCardPreview INIT",
        component: () => import("@/views/initiator/techcards/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.techCardPreviewInit",
          groupParent: "breadcrumb.initiator",
        },
      },
      {
        path: "accounting/invoice",
        name: "Accounting invoice",
        component: () => import("@/views/accounting/invoice"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "partner/invoice",
        name: "Partner invoice",
        component: () => import("@/views/partner/invoice"),
        meta: {
          groupParent: "Utility",
        },
      },
     
      {
        path: "accounting/invoice-edit",
        name: "Accounting invoice-edit",
        component: () => import("@/views/accounting/invoice/invoice-edit"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "accounting/invoice-add",
        name: "Accounting invoice-add",
        component: () => import("@/views/accounting/invoice/invoice-add"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "accounting/offers",
        name: "offersAccounting",
        component: () => import("@/views/accounting/offers"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.offersAccount",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "partner/offers",
        name: "offersPartner",
        component: () => import("@/views/partner/offers"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.offersAccount",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "accounting/invoice-preview",
        name: "KPPreview",
        component: () => import("@/views/accounting/offers/kp-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.kpPreviewAuthor",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "accounting/invoice-preview",
        name: "InvoicePreview",
        component: () => import("@/views/accounting/offers/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.invoicePreviewAuthor",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "partner/invoice-preview",
        name: "KPPreview partner",
        component: () => import("@/views/partner/offers/kp-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.kpPreviewAuthor",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "partner/invoice-preview",
        name: "InvoicePreview partner",
        component: () => import("@/views/partner/offers/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.invoicePreviewAuthor",
          groupParent: "breadcrumb.accounting",
        },
      },
      {
        path: "supervisor/techcards",
        name: "Техкарты supervisor" ,
        component: () => import("@/views/supervisor/techcards"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.techcards",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/invoice",
        name: "Superuser invoice",
        component: () => import("@/views/supervisor/invoice"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "supervisor/offers",
        name: "offersSU",
        component: () => import("@/views/supervisor/offers"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.offersAccount",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/invoice-preview",
        name: "KPPreview",
        component: () => import("@/views/supervisor/offers/kp-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.kpPreviewAuthor",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/invoice-preview",
        name: "InvoicePreview",
        component: () => import("@/views/supervisor/offers/invoice-preview.vue"),
        meta: {
          hide: true,
          titleKey: "breadcrumb.invoicePreviewAuthor",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/calendar",
        name: "Calender supervisor",
        component: () => import("@/views/author/calendar"),
      },
      {
        path: "supervisor/booktracking",
        name: "BookTracking supervisor",
        component: () => import("@/views/supervisor/techcards/BookTracking.vue"), // Adjusted import path
        meta: {
          hide: true,
          titleKey: "breadcrumb.bookTrackingInitiator",
          groupParent: "breadcrumb.superuser",
        },
      },
      {
        path: "supervisor/kanban",
        name: "Kanban supervisor",
        component: () => import("@/views/supervisor/kanban"),
        meta: {
          hide: true,
        },
      },
      {
        path: "chat",
        name: "chat",
        component: () => import("@/views/app/chat"),
        meta: {
          hide: true,
          appheight: true,
        },
      },
      // normal pages
      {
        path: "invoice",
        name: "invoice",
        component: () => import("@/views/utility/invoice"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "invoice-preview",
        name: "invoice-preview",
        component: () => import("@/views/utility/invoice/invoice-preview"),
        meta: {
          hide: true,
        },
      },
      {
        path: "invoice-edit",
        name: "invoice-edit",
        component: () => import("@/views/utility/invoice/invoice-edit"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "invoice-add",
        name: "invoice-add",
        component: () => import("@/views/utility/invoice/invoice-add"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "Pricing",
        name: "pricing",
        component: () => import("@/views/utility/pricing"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "faq",
        name: "faq",
        component: () => import("@/views/utility/faq"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "blog",
        name: "blog",
        component: () => import("@/views/utility/blog"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "blog-details",
        name: "blog-details",
        component: () => import("@/views/utility/blog/blog-details"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "testimonial",
        name: "testimonial",
        component: () => import("@/views/utility/testimonial"),
        meta: {
          groupParent: "Utility",
        },
      },
      {
        path: "map",
        name: "map",
        component: () => import("@/views/map"),
      },
      {
        path: "profile/:id",
        name: "profile",
        component: () => import("@/views/profile.vue"),
      },
      {
        path: 'profile-setting',
        name: 'ProfileSetting',
        component: () => import("@/views/profile-setting.vue"),
      },
     
      {
        path: "settings",
        name: "settings",
        component: () => import("@/views/settings.vue"),
      },
      {
        path: "icons",
        name: "icons",
        component: () => import("@/views/icons.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  {
    path: "/coming-soon",
    name: "coming-soon",
    component: () => import("@/views/utility/comming-soon"),
  },
  {
    path: "/under-construction",
    name: "under-construction",
    component: () => import("@/views/utility/under-construction"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  const titleText = to.name;
  const words = titleText.split(" ");
  const wordslength = words.length;
  for (let i = 0; i < wordslength; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  document.title = "Smart University Press  - " + words;
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
